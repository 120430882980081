import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CreativeEntityState, creativeAdapter } from './creative.reducer';

const selectCreativeState =
  createFeatureSelector<CreativeEntityState>('creative');

export interface CreativeThumbnailViewModel {
  creativeName: string;
  thumbnailUrl?: string;
  gifUrl?: string;
  hasEntity: boolean;
  hasRecording: boolean;
}

export interface CreativeRecordingViewModel {
  recordingUrl?: string;
  creativeName?: string;
}

export const selectCreativeThumbnailUrl = (creativeId: string) =>
  createSelector(selectCreativeState, (state): CreativeThumbnailViewModel => {
    return {
      creativeName: state.entities[creativeId]?.creative.name || '',
      gifUrl: state.entities[creativeId]?.gifUrl,
      thumbnailUrl: state.entities[creativeId]?.thumbnailUrl,
      hasEntity: !!state.entities[creativeId],
      hasRecording: !!state.entities[creativeId]?.creative.recording,
    };
  });

export const selectCreativeRecordingUrl = (creativeId: string) =>
  createSelector(selectCreativeState, (state): CreativeRecordingViewModel => {
    return {
      recordingUrl: state.entities[creativeId]?.recordingUrl,
      creativeName: state.entities[creativeId]?.creative.name,
    };
  });

export const selectCreativeById = (creativeId: string) =>
  createSelector(selectCreativeState, (state) => {
    return state.entities[creativeId]?.creative;
  });

export const selectCreativesByIds = (creativeIds: string[]) =>
  createSelector(selectCreativeState, (state) => {
    return creativeIds.map((id) => state.entities[id]?.creative);
  });

export const selectCreativeWithResourcesById = (creativeId: string) =>
  createSelector(selectCreativeState, (state) => {
    return state.entities[creativeId];
  });

export const selectCreativesWithResourcesByIds = (creativeIds: string[]) =>
  createSelector(selectCreativeState, (state) => {
    return creativeIds.map((id) => state.entities[id]);
  });

export const {
  selectAll: selectAllCreatives,
  selectEntities: selectCreativeEntities,
} = creativeAdapter.getSelectors(selectCreativeState);
