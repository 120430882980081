import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-repeat',
  templateUrl: './repeat.component.html',
  imports: [CommonModule, FormlyModule],
})
export class RepeatComponent extends FieldArrayType {
  initialModel() {
    if (this.props['model']) {
      return new this.props['model']();
    }
    return undefined;
  }

  override remove(i: number) {
    // Mark as dirty before the value update so changes will be recognised
    this.form.markAsDirty();
    super.remove(i, { markAsDirty: true });
  }
}
