import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-input',
  templateUrl: './input.component.html',
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class InputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  type = 'text';

  ngOnInit() {
    if (this.props.type) {
      this.type = this.props.type;
    }
  }
}
