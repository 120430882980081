import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { JOB_STORE_PROVIDERS } from '@sites/dashboard/feature-job';
import { PRESENCE_STORE_PROVIDERS } from '@sites/dashboard/feature-presence';
import { SHARE_STORE_PROVIDERS } from '@sites/dashboard/feature-share';
import {
  ROOT_STORE_PROVIDERS,
  effects,
  reducers,
} from '@sites/dashboard/store';
import { UiFormlyModule } from '@sites/dashboard/ui-formly';
import { DataAuthModule } from '@sites/data-auth';
import { DataConnectModule } from '@sites/data-connect';
import { ENVIRONMENT } from '@sites/util-environment';
import { UtilErrorsModule } from '@sites/util-errors';
import { UtilNavigationModule } from '@sites/util-navigation';
import { Parser } from 'marked';
import {
  MARKED_OPTIONS,
  MarkedOptions,
  MarkedRenderer,
  provideMarkdown,
} from 'ngx-markdown';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

// configure the markdown renderer.
function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const parser = new Parser();
  const linkRenderer = renderer.link;
  renderer.parser = parser;
  renderer.link = (tokens) => {
    const html = linkRenderer.call(renderer, tokens);
    return html.replace(
      /^<a /,
      '<a role="link" target="_blank" rel="nofollow noopener nereferrer" '
    );
  };
  return {
    renderer,
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    BrowserModule,
    BrowserAnimationsModule,
    provideRouter(
      appRoutes,
      withRouterConfig({
        // Allows us to refresh data when reloading the same URL
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        // Disables scroll to top functionality for all query param only changes
        get scrollPositionRestoration() {
          // Make sure we are in a browser environment
          if (document) {
            // Remove the query string from the current page and referrer
            const currentWithoutQueryString =
              document.location.href.split('?')[0];
            const referrerWithoutQueryString = document.referrer.split('?')[0];

            // Check to see if the pages without a query string are the same
            if (currentWithoutQueryString === referrerWithoutQueryString) {
              return 'disabled';
            }
          }
          return 'enabled';
        },
      })
    ),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
    importProvidersFrom(
      UtilErrorsModule.forRoot(),
      DataConnectModule.forRoot({
        baseUrl: environment.apiUrl,
      }),
      UtilNavigationModule.forRoot({
        titleSuffix: 'Dashboard',
        titleSeparator: ' | ',
      }),
      DataAuthModule.forRoot({
        authority: environment.authAuthority,
        redirectUrl: environment.authRedirectUrl,
        postLogoutRedirectUrl: environment.authPostLogoutRedirectUrl,
        unauthorizedRoute: environment.authUnauthorizedRoute,
        clientId: environment.authClientId,
      }),
      StoreModule.forRoot({
        ...reducers,
      }),
      EffectsModule.forRoot([...effects]),
      StoreRouterConnectingModule.forRoot(),
      // Reduce bundle size by only bundling modules needed for an environment
      ...environment.appImports,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      UiFormlyModule
    ),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        hasBackdrop: true,
        maxWidth: '48rem',
        width: '100%',
        autoFocus: 'first-tabbable',
        restoreFocus: true,
      },
    },
    ...ROOT_STORE_PROVIDERS,
    ...JOB_STORE_PROVIDERS,
    ...SHARE_STORE_PROVIDERS,
    ...PRESENCE_STORE_PROVIDERS,
  ],
};
