import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Code, ConnectError } from '@connectrpc/connect';
import { AuthService } from '@sites/data-auth';
import { NavigationService } from '@sites/util-navigation';

@Component({
  selector: 'dashboard-root',
  templateUrl: './app.component.html',
  imports: [RouterModule],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) document: Document,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.authService.user$.subscribe((user) => {
      if (document.defaultView) {
        // @ts-expect-error gtag is available on the window
        document.defaultView.gtag('set', 'user_properties', {
          org: user.org,
        });
        // @ts-expect-error gtag is available on the window
        document.defaultView.gtag('set', 'user_id', user.email);
      }
    });

    // Handle navigation errors, if resolvers fail navigation isn't
    // completed and links will look broken. Instead handle the error
    // and redirect to an appropriate error page.
    this.navigationService.routeErrored$.subscribe((event) => {
      if (event.error instanceof ConnectError) {
        switch (event.error.code) {
          case Code.Unknown:
            // Not an error, do nothing
            break;
          case Code.Unauthenticated:
            // Handled be error interceptor
            break;
          case Code.PermissionDenied:
            this.router.navigate(['error', 'denied']);
            break;
          default:
            this.router.navigate(['error']);
            break;
        }
      }
    });
  }
}
