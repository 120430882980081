// @generated by protoc-gen-es v2.2.0
// @generated from file hmm/incubator/presence_service.proto (package hmm.incubator, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_timestamp } from "../../google/protobuf/timestamp_pb";
import { file_hmm_orgs_orgs } from "../orgs/orgs_pb";

/**
 * Describes the file hmm/incubator/presence_service.proto.
 */
export const file_hmm_incubator_presence_service = /*@__PURE__*/
  fileDesc("CiRobW0vaW5jdWJhdG9yL3ByZXNlbmNlX3NlcnZpY2UucHJvdG8SDWhtbS5pbmN1YmF0b3IiHAoMV2F0Y2hSZXF1ZXN0EgwKBGtleXMYASADKAkiQgoNV2F0Y2hSZXNwb25zZRIxCg1wcmVzZW50X3VzZXJzGAEgAygLMhouaG1tLmluY3ViYXRvci5QcmVzZW50VXNlciJWCgtQcmVzZW50VXNlchIcCgR1c2VyGAEgASgLMg4uaG1tLm9yZ3MuVXNlchIpCghwcmVzZW5jZRgCIAMoCzIXLmhtbS5pbmN1YmF0b3IuUHJlc2VuY2UidgoIUHJlc2VuY2USCwoDa2V5GAEgASgJEi0KCWxhc3Rfc2VlbhgCIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASLgoKZmlyc3Rfc2VlbhgDIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXAiIAoQSGVhcnRiZWF0UmVxdWVzdBIMCgRrZXlzGAEgAygJIhMKEUhlYXJ0YmVhdFJlc3BvbnNlMqsBCg9QcmVzZW5jZVNlcnZpY2USRgoFV2F0Y2gSGy5obW0uaW5jdWJhdG9yLldhdGNoUmVxdWVzdBocLmhtbS5pbmN1YmF0b3IuV2F0Y2hSZXNwb25zZSIAMAESUAoJSGVhcnRiZWF0Eh8uaG1tLmluY3ViYXRvci5IZWFydGJlYXRSZXF1ZXN0GiAuaG1tLmluY3ViYXRvci5IZWFydGJlYXRSZXNwb25zZSIAQjdaNWdpdGh1Yi5jb20vaHVtYW4tbWFkZS1tYWNoaW5lL2htbS9pbmN1YmF0b3I7aW5jdWJhdG9yYgZwcm90bzM", [file_google_protobuf_timestamp, file_hmm_orgs_orgs]);

/**
 * Describes the message hmm.incubator.WatchRequest.
 * Use `create(WatchRequestSchema)` to create a new message.
 */
export const WatchRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 0);

/**
 * Describes the message hmm.incubator.WatchResponse.
 * Use `create(WatchResponseSchema)` to create a new message.
 */
export const WatchResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 1);

/**
 * Describes the message hmm.incubator.PresentUser.
 * Use `create(PresentUserSchema)` to create a new message.
 */
export const PresentUserSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 2);

/**
 * Describes the message hmm.incubator.Presence.
 * Use `create(PresenceSchema)` to create a new message.
 */
export const PresenceSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 3);

/**
 * Describes the message hmm.incubator.HeartbeatRequest.
 * Use `create(HeartbeatRequestSchema)` to create a new message.
 */
export const HeartbeatRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 4);

/**
 * Describes the message hmm.incubator.HeartbeatResponse.
 * Use `create(HeartbeatResponseSchema)` to create a new message.
 */
export const HeartbeatResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_incubator_presence_service, 5);

/**
 * Creatives service.
 *
 * @generated from service hmm.incubator.PresenceService
 */
export const PresenceService = /*@__PURE__*/
  serviceDesc(file_hmm_incubator_presence_service, 0);

