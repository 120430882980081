import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PresentUser } from '@sites/data-hmm/hmm-incubator';

export const panelActions = createActionGroup({
  source: 'DashboardFeaturePresence PanelComponent',
  events: {
    init: props<{ key: string }>(),
    destroy: props<{ key: string }>(),
  },
});

export const effectsActions = createActionGroup({
  source: 'DashboardFeaturePresence Effects',
  events: {
    HeartbeatSuccess: emptyProps(),
    HeartbeatFailure: props<{ error: Error }>(),
    WatchSuccess: props<{ presentUsers: PresentUser[] }>(),
    WatchFailure: props<{ error: Error }>(),
    WatchFinalize: emptyProps(),
  },
});
