import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-textarea',
  templateUrl: './textarea.component.html',
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class TextareaComponent extends FieldType<FieldTypeConfig> {}
