import { Timestamp } from '@sites/data-hmm/google-protobuf';
import { PresentUser } from '@sites/data-hmm/hmm-incubator';

export interface PresenceState {
  watchedKeys?: string[];
  presentUsers?: PresentUser[];
  error?: Error;
}

export const initialPresenceState: PresenceState = {};

export interface UserWithPresence {
  name: string;
  email: string;
  picture: string;
  firstSeen?: Timestamp;
}

export interface PanelViewModel {
  users: UserWithPresence[];
  error?: Error;
}
