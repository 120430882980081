import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-color',
  templateUrl: './color.component.html',
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class ColorComponent extends FieldType<FieldTypeConfig> {
  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.formControl.setValue(event.target.value);
    }
  }
}
