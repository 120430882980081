import { importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideClient } from '@sites/data-connect';
import { PresenceService } from '@sites/data-hmm/hmm-incubator';
import { PresenceEffects } from './store.effects';
import { PresenceKey, presenceReducer } from './store.reducer';

export const PRESENCE_STORE_PROVIDERS = [
  provideClient(PresenceService),
  importProvidersFrom(
    StoreModule.forFeature(PresenceKey, presenceReducer),
    EffectsModule.forFeature([PresenceEffects])
  ),
];
