import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Creative } from '@sites/data-hmm/hmm-incubator';
import { produce } from 'immer';
import { creativeServiceAction } from './creative.actions';

export interface CreativeWithResources {
  thumbnailUrl?: string;
  thumbnailLoadedTime?: number;
  gifUrl?: string;
  gifLoadedTime?: number;
  recordingUrl?: string;
  recordingLoadedTime?: number;
  creative: Creative;
}

export interface CreativeEntityState
  extends EntityState<CreativeWithResources> {
  error?: Error;
}

export function selectCreativeId(creativeWithResources: CreativeWithResources) {
  return creativeWithResources.creative.id;
}

export const creativeAdapter: EntityAdapter<CreativeWithResources> =
  createEntityAdapter<CreativeWithResources>({
    selectId: selectCreativeId,
  });

export const initialCreativeState: CreativeEntityState =
  creativeAdapter.getInitialState({
    error: undefined,
  });

export const creativeReducer = createReducer(
  initialCreativeState,
  on(creativeServiceAction.listSuccess, (state, { creatives }) => {
    return creativeAdapter.upsertMany(
      creatives.map((c) => {
        return { creative: c };
      }),
      state
    );
  }),

  on(creativeServiceAction.loadThumbnailsSuccess, (state, { thumbnails }) => {
    return creativeAdapter.updateMany(
      thumbnails.map((c) => {
        return {
          id: c.creativeId,
          changes: {
            thumbnailUrl: c.thumbnailUrl,
            thumbnailLoadedTime: new Date().getTime(),
          },
        };
      }),
      state
    );
  }),
  on(creativeServiceAction.loadGifSuccess, (state, { creativeId, gifUrl }) => {
    return creativeAdapter.updateOne(
      {
        id: creativeId,
        changes: { gifUrl, gifLoadedTime: new Date().getTime() },
      },
      state
    );
  }),
  on(
    creativeServiceAction.loadRecordingSuccess,
    (state, { creativeId, recordingUrl }) => {
      return creativeAdapter.updateOne(
        {
          id: creativeId,
          changes: { recordingUrl, recordingLoadedTime: new Date().getTime() },
        },
        state
      );
    }
  ),
  on(
    creativeServiceAction.listFailure,
    creativeServiceAction.loadRecordingFailure,
    creativeServiceAction.loadThumbnailsFailure,
    creativeServiceAction.loadGifFailure,
    (state, { error }) =>
      produce(state, (draft) => {
        draft.error = error;
      })
  )
);
