import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { effectsActions, panelActions } from './store.actions';
import { initialPresenceState } from './store.models';

export const PresenceKey = 'DashboardFeaturePresence';

export const presenceReducer = createReducer(
  initialPresenceState,
  on(panelActions.init, (state, { key }) =>
    produce(state, (draft) => {
      draft.watchedKeys = [...(draft.watchedKeys || []), key];
    })
  ),
  on(panelActions.destroy, (state, { key }) =>
    produce(state, (draft) => {
      draft.watchedKeys = draft.watchedKeys?.filter((k) => k !== key);
    })
  ),
  on(effectsActions.watchSuccess, (state, { presentUsers }) =>
    produce(state, (draft) => {
      draft.presentUsers = presentUsers;
    })
  ),
  on(
    effectsActions.watchFailure,
    effectsActions.heartbeatFailure,
    (state, { error }) =>
      produce(state, (draft) => {
        draft.error = error;
      })
  )
);
