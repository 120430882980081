import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-wrapper',
  templateUrl: './wrapper.component.html',
  imports: [CommonModule, FormlyModule],
})
export class WrapperComponent extends FieldWrapper {}
