import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { PresenceService } from '@sites/data-hmm/hmm-incubator';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  interval,
  map,
  of,
  pipe,
  switchMap,
} from 'rxjs';
import { effectsActions, panelActions } from './store.actions';
import { selectWatchedKeys } from './store.selectors';

const WATCH_INTERVAL = 30000;

@Injectable()
export class PresenceEffects {
  private watchAbortController?: AbortController;

  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(PresenceService)
    private presenceService: ObservableClient<typeof PresenceService>
  ) {}

  heartbeat$ = createEffect(() => {
    return interval(WATCH_INTERVAL).pipe(this.sendHeartbeat());
  });

  panel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(panelActions.init, panelActions.destroy),
      this.sendHeartbeat()
    );
  });

  watch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        panelActions.init,
        panelActions.destroy,
        effectsActions.heartbeatSuccess
      ),
      concatLatestFrom(() => this.store.select(selectWatchedKeys)),
      distinctUntilChanged<[unknown, string[] | undefined]>(
        ([, previous], [, current]) => {
          const previousSet = new Set(previous);
          const currentSet = new Set(current);
          return (
            (previous ?? []).every((item) => currentSet.has(item)) &&
            (current ?? []).every((item) => previousSet.has(item))
          );
        }
      ),
      this.setupWatch()
    );
  });

  finalize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(effectsActions.watchFinalize),
      this.setupWatch()
    );
  });

  private sendHeartbeat() {
    return pipe(
      concatLatestFrom(() => this.store.select(selectWatchedKeys)),
      filter(([_, keys]) => (keys?.length ?? 0) > 0),
      switchMap(([, keys]) =>
        this.presenceService.heartbeat({ keys }).pipe(
          map(() => effectsActions.heartbeatSuccess()),
          catchError((error: Error) =>
            of(effectsActions.heartbeatFailure({ error }))
          )
        )
      )
    );
  }

  private setupWatch() {
    return pipe(
      switchMap(([, keys]) => {
        // Something has changed so kill the old watch
        this.watchAbortController?.abort();
        this.watchAbortController = new AbortController();

        if (keys?.length === 0) {
          return of();
        }

        return this.presenceService
          .watch({ keys }, { signal: this.watchAbortController?.signal })
          .pipe(
            map(({ presentUsers }) =>
              effectsActions.watchSuccess({ presentUsers })
            ),
            catchError((error: Error) =>
              of(effectsActions.watchFailure({ error }))
            ),
            finalize(() => of(effectsActions.watchFinalize()))
          );
      })
    );
  }
}
