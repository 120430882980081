// @generated by protoc-gen-es v2.2.0
// @generated from file hmm/ninja/panels.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, messageDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";
import { file_google_type_money } from "../../google/type/money_pb";

/**
 * Describes the file hmm/ninja/panels.proto.
 */
export const file_hmm_ninja_panels = /*@__PURE__*/
  fileDesc("ChZobW0vbmluamEvcGFuZWxzLnByb3RvEglobW0ubmluamEiLgoFUGFuZWwSCgoCaWQYASABKAkSCwoDc3JjGAIgASgJEgwKBG5hbWUYAyABKAkirQYKC1BhbmVsQ29uZmlnEhAKCHBhbmVsX2lkGAEgASgJEhEKCXBhbmVsX3NyYxgNIAEoCRIRCglzdXJ2ZXlfaWQYAiABKAkSLQoGc3RhdHVzGAogASgOMh0uaG1tLm5pbmphLlBhbmVsQ29uZmlnLlN0YXR1cxIZChFmaW5hbmNlX2VudGl0eV9pZBgMIAEoCRIXCg9yZXF1aXJlZF9zYW1wbGUYAyABKAUSPgoPcmVkaXJlY3RfdG9rZW5zGAQgASgLMiUuaG1tLm5pbmphLlBhbmVsQ29uZmlnLlJlZGlyZWN0VG9rZW5zEiEKGWRpc3RyaWJ1dGlvbl91cmxfdGVtcGxhdGUYBiABKAkSDwoHY29udGFjdBgHIAEoCRIRCglyZWZlcmVuY2UYCCABKAkSCgoCcG8YCSABKAkSKAoMZm9yZWNhc3RfY3BpGBkgASgLMhIuZ29vZ2xlLnR5cGUuTW9uZXkSKQoNZm9yZWNhc3RfZmVlcxgeIAEoCzISLmdvb2dsZS50eXBlLk1vbmV5EikKDWZvcmVjYXN0X2Nvc3QYHyABKAsyEi5nb29nbGUudHlwZS5Nb25leRIsCgxpbnZvaWNlZF9jcGkYIyABKAsyEi5nb29nbGUudHlwZS5Nb25leUICGAESLQoNaW52b2ljZWRfZmVlcxgoIAEoCzISLmdvb2dsZS50eXBlLk1vbmV5QgIYARItCg1pbnZvaWNlZF9jb3N0GCkgASgLMhIuZ29vZ2xlLnR5cGUuTW9uZXlCAhgBEhgKDGludm9pY2VfcGFpZBgtIAEoCEICGAESGQoNYmlsbF9yZWNlaXZlZBguIAEoCEICGAEaWwoOUmVkaXJlY3RUb2tlbnMSEgoKcXVvdGFfZnVsbBgBIAEoCRISCgpzY3JlZW5fb3V0GAIgASgJEhAKCGNvbXBsZXRlGAMgASgJEg8KB3F1YWxpdHkYBCABKAkiRgoGU3RhdHVzEhIKDlNUQVRVU19VTktOT1dOEAASEwoPU1RBVFVTX0ZPUkVDQVNUEAoSEwoPU1RBVFVTX1JFUE9SVEVEEBRKBAgLEAxKBAgFEAZCL1otZ2l0aHViLmNvbS9odW1hbi1tYWRlLW1hY2hpbmUvaG1tL25pbmphO25pbmphYgZwcm90bzM", [file_google_type_money]);

/**
 * Describes the message hmm.ninja.Panel.
 * Use `create(PanelSchema)` to create a new message.
 */
export const PanelSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 0);

/**
 * Describes the message hmm.ninja.PanelConfig.
 * Use `create(PanelConfigSchema)` to create a new message.
 */
export const PanelConfigSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 1);

/**
 * Describes the message hmm.ninja.PanelConfig.RedirectTokens.
 * Use `create(PanelConfig_RedirectTokensSchema)` to create a new message.
 */
export const PanelConfig_RedirectTokensSchema = /*@__PURE__*/
  messageDesc(file_hmm_ninja_panels, 1, 0);

/**
 * Describes the enum hmm.ninja.PanelConfig.Status.
 */
export const PanelConfig_StatusSchema = /*@__PURE__*/
  enumDesc(file_hmm_ninja_panels, 1, 0);

/**
 * @generated from enum hmm.ninja.PanelConfig.Status
 */
export const PanelConfig_Status = /*@__PURE__*/
  tsEnum(PanelConfig_StatusSchema);

