import { Routes } from '@angular/router';
import {
  hasStoreAuth,
  serviceWorkerCheckForUpdate,
} from '@sites/dashboard/store';
import { DevelopmentGuard, LoginGuard, StaffGuard } from '@sites/data-auth';
import { Grant_Action, Grant_Namespace } from '@sites/data-hmm/hmm-authz';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivateChild: [serviceWorkerCheckForUpdate],
    children: [
      {
        path: 'home',
        canActivate: [LoginGuard],
        component: HomeComponent,
        data: {
          title: 'Home',
        },
      },
      {
        path: 'organisations',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.ORGS_ORG,
            action: Grant_Action.UPDATE,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-organisation').then((m) => m.ROUTES),
      },
      {
        path: 'tracker',
        canLoad: [LoginGuard],
        canActivate: [StaffGuard],
        loadChildren: () =>
          import('@sites/dashboard/feature-tracker').then((m) => m.ROUTES),
      },
      {
        path: 'classification',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.CLASSIFICATION_SURVEY,
            action: Grant_Action.UPDATE,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-classification').then(
            (m) => m.ROUTES
          ),
      },
      {
        path: 'panels',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.NINJA_PANELS,
            action: Grant_Action.CREATE,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-panel').then((m) => m.ROUTES),
      },
      {
        path: 'property-config',
        redirectTo: '/study-admin',
        pathMatch: 'full',
      },
      {
        path: 'property-config/:studyId',
        redirectTo: '/study-admin/:studyId',
        pathMatch: 'full',
      },
      {
        path: 'study-config',
        redirectTo: '/study-admin',
        pathMatch: 'full',
      },
      {
        path: 'study-config/:studyId',
        redirectTo: '/study-admin/:studyId',
        pathMatch: 'full',
      },
      {
        path: 'study-admin',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth],
        data: {
          // Because Panels have access to the Sample page allow them through
          hasStoreAuth: {
            namespace: Grant_Namespace.NINJA_PANELS,
            action: Grant_Action.READ,
          },
          // Preload these checks so they are available for figuring out which pages to show
          hasPreloadStoreAuth: [
            {
              namespace: Grant_Namespace.INCUBATOR_STUDY,
              action: Grant_Action.UPDATE,
            },
            {
              namespace: Grant_Namespace.INCUBATOR_STUDY,
              action: Grant_Action.READ,
            },
          ],
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-study-admin').then((m) => m.ROUTES),
      },
      {
        path: 'cohorts',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.NINJA_COHORTS,
            action: Grant_Action.READ,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-cohort').then((m) => m.ROUTES),
      },
      {
        path: 'project',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.PROJECTS_QUOTE,
            action: Grant_Action.READ,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-project').then((m) => m.ROUTES),
      },
      {
        path: 'quote',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.PROJECTS_QUOTE,
            action: Grant_Action.READ,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-quote').then((m) => m.ROUTES),
      },
      {
        path: 'distribution-link',
        canLoad: [LoginGuard],
        canActivate: [StaffGuard],
        loadChildren: () =>
          import('@sites/dashboard/feature-distribution-link').then(
            (m) => m.ROUTES
          ),
      },
      {
        path: 'reconcile',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.PROJECTS_FINANCE_REFERENCE,
            action: Grant_Action.CREATE,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-reconcile').then(
            (m) => m.RECONCILE_ROUTES
          ),
      },
      {
        path: 'developer',
        canLoad: [LoginGuard],
        canActivate: [DevelopmentGuard],
        loadChildren: () =>
          import('@sites/dashboard/feature-developer').then((m) => m.ROUTES),
      },
      {
        path: 'pipeline',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth, StaffGuard],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.PROJECTS_QUOTE,
            action: Grant_Action.CREATE,
          },
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-pipeline').then((m) => m.ROUTES),
      },
      {
        path: 'studies',
        canLoad: [LoginGuard],
        canActivate: [hasStoreAuth],
        data: {
          hasStoreAuth: {
            namespace: Grant_Namespace.INCUBATOR_RESULT,
            action: Grant_Action.READ,
          },
          title: 'Studies',
        },
        loadChildren: () =>
          import('@sites/dashboard/feature-study').then((m) => m.ROUTES),
      },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'error',
        loadChildren: () =>
          import('./error/error.routes').then((m) => m.ROUTES),
      },
      {
        path: 'survey-link',
        canLoad: [LoginGuard],
        loadChildren: () =>
          import('@sites/dashboard/feature-survey-link').then((m) => m.ROUTES),
      },
      {
        path: '**',
        component: ErrorComponent,
        data: {
          title: 'Not Found',
        },
      },
    ],
  },
];
