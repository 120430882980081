import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGE_COMPONENTS } from '@sites/dashboard/ui';
import { AuthService } from '@sites/data-auth';
import { MenuItem, MenuService } from '../menu/menu.service';

@Component({
  selector: 'dashboard-home',
  templateUrl: './home.component.html',
  imports: [RouterModule, PAGE_COMPONENTS],
})
export class HomeComponent implements OnInit {
  firstName?: string;
  email?: string;

  menuItems: MenuItem[] = [];

  constructor(
    private authService: AuthService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.menuService.getItems().subscribe((items) => {
      this.menuItems = items;
    });

    this.authService.user$.subscribe((user) => {
      this.firstName = user.firstName();
      this.email = user.email;
    });
  }
}
