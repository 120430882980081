import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PresenceState, UserWithPresence } from './store.models';
import { PresenceKey } from './store.reducer';

const selectPresenceState = createFeatureSelector<PresenceState>(PresenceKey);

export const selectWatchedKeys = createSelector(
  selectPresenceState,
  (state) => state.watchedKeys
);

export const selectPresentUsers = (key: string) =>
  createSelector(selectPresenceState, (state) =>
    state.presentUsers?.filter((u) => u.presence.some((p) => p.key === key))
  );

export const selectPanelViewModel = (key: string) =>
  createSelector(
    selectPresenceState,
    selectPresentUsers(key),
    (state, presentUsers) => {
      const users: UserWithPresence[] = [];
      if (state.presentUsers) {
        presentUsers?.forEach((p) => {
          const presence = p.presence.find((pr) => pr.key === key);
          if (presence && p.user) {
            users.push({
              name: p.user.name,
              email: p.user.email,
              picture: p.user.picture,
              firstSeen: presence.firstSeen,
            });
          }
        });
      }

      return {
        users,
        error: state.error,
      };
    }
  );
