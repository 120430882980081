import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-model',
  templateUrl: './model.component.html',
  imports: [CommonModule, FormlyModule],
})
export class ModelComponent extends FieldType {}
