import { createActionGroup, props } from '@ngrx/store';
import { Creative } from '@sites/data-hmm/hmm-incubator';

export const creativeServiceAction = createActionGroup({
  source: 'DashboardStore CreativeService',
  events: {
    Get: props<{ creativeId: string }>(),
    ListSuccess: props<{
      creatives: Creative[];
    }>(),
    ListFailure: props<{ error: Error }>(),

    RefreshThumbnails: props<{ creatives: Creative[] }>(),

    LoadThumbnailsSuccess: props<{
      thumbnails: Array<{ creativeId: string; thumbnailUrl: string }>;
    }>(),
    LoadThumbnailsFailure: props<{ error: Error }>(),

    LoadGif: props<{ creativeId: string }>(),
    LoadGifSuccess: props<{
      creativeId: string;
      gifUrl: string;
    }>(),
    LoadGifFailure: props<{ error: Error }>(),

    LoadRecording: props<{ creativeId: string }>(),
    LoadRecordingSuccess: props<{
      creativeId: string;
      recordingUrl: string;
    }>(),
    LoadRecordingFailure: props<{ error: Error }>(),
  },
});
