import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'property-form-time',
  templateUrl: './time.component.html',
  imports: [CommonModule, FormlyModule, FormsModule],
})
export class TimeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  isMillisecond = false;

  timeModel = {
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  };

  ngOnInit() {
    this.timeModel.milliseconds = this.formControl.value;
    this.timeModel.minutes = this.msToMinutes(this.formControl.value);
    this.timeModel.seconds = this.msToSeconds(this.formControl.value);

    this.formControl.valueChanges.subscribe((value) => {
      this.timeModel.milliseconds = value;
      this.timeModel.seconds = this.msToSeconds(value);
      this.timeModel.minutes = this.msToMinutes(value);
    });
  }

  onChange() {
    if (this.isMillisecond) {
      this.timeModel.seconds = this.msToSeconds(this.timeModel.milliseconds);
      this.timeModel.minutes = this.msToMinutes(this.timeModel.milliseconds);
    } else {
      this.timeModel.milliseconds = this.minSecToMs(
        this.timeModel.minutes,
        this.timeModel.seconds
      );
    }
    this.formControl.markAsDirty();
    this.formControl.setValue(this.timeModel.milliseconds);
  }

  private msToSeconds(ms?: number): number {
    return ((ms ?? 0) % 60000) / 1000;
  }

  private msToMinutes(ms?: number): number {
    return Math.floor((ms ?? 0) / 60000);
  }

  private minSecToMs(minutes?: number, seconds?: number): number {
    return Math.round((minutes ?? 0) * 60000 + (seconds ?? 0) * 1000);
  }
}
